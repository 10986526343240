import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Box from 'gatsby-theme-signalwerk/src/components/Box';
export const _frontmatter = {
  "title": "Type Founding",
  "author": "Stefan Huber",
  "date": "2020-04-08",
  "layout": "post",
  "draft": false,
  "hideInMenu": false,
  "path": "/posts/type-founding/",
  "tags": ["TagOne", "TagTwo"],
  "description": "Type founding for letterpress was for several houndred years manual labour. Let's have a look at it!"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "awesome"
    }}>{`Awesome!`}</h2>
    <p>{`I just found in the `}<a parentName="p" {...{
        "href": "https://www.verein-fuer-die-schwarze-kunst.de/community/forum-wissen/handgiessinstrument-nachbauten/"
      }}>{`forums of «Verein fuer die Schwarze Kunst»`}</a>{` some interestening links. One of it was Stan Nelson, `}<a parentName="p" {...{
        "href": "https://www.youtube.com/channel/UC_dp105NUyfCMtapr3rQEwA"
      }}>{`YouTube Channel`}</a>{` where the historian of printing history explains how type was casted for several hundred years.`}</p>
    <h2 {...{
      "id": "punchcutting"
    }}>{`Punchcutting`}</h2>
    <Box ratio="16:9" mdxType="Box">
      <iframe src="https://www.youtube-nocookie.com/embed/eExllUeGtvc" frameBorder="0"></iframe>
    </Box>
    <h2 {...{
      "id": "tempering-punches-and-striking-matrices"
    }}>{`Tempering Punches and Striking Matrices`}</h2>
    <Box ratio="16:9" mdxType="Box">
      <iframe src="https://www.youtube-nocookie.com/embed/QyiCPzERIGA" frameBorder="0"></iframe>
    </Box>
    <h2 {...{
      "id": "casting-type"
    }}>{`Casting Type`}</h2>
    <Box ratio="16:9" mdxType="Box">
      <iframe src="https://www.youtube-nocookie.com/embed/QyiCPzERIGA" frameBorder="0"></iframe>
    </Box>
    <h2 {...{
      "id": "contrast"
    }}>{`Contrast`}</h2>
    <p>{`As a hard contrast: Dafi Kühne shows us how he's doing it in the 21th Century. Casting Plastic Resin Type brought to you by his «Printing Show™» 🚀`}</p>
    <Box ratio="16:9" mdxType="Box">
      <iframe src="https://player.vimeo.com/video/279991738" frameBorder="0"></iframe>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      